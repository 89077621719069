<template>
  <main class="container my-3 text-center">
    <h1 class="raleway-extra-bold">Impressum</h1>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>
      Jonas Mader<br />
      S&uuml;ss-Saure<br />
      Memh&ouml;lz 3 1/4<br />
      87448 Waltenhofen
    </p>

    <h2>Kontakt</h2>
    <p>
      Telefon: +49 151 55102902<br />
      E-Mail: servus@suess-saure.de
    </p>

    <h2>Redaktionell verantwortlich</h2>
    <p>Jonas Mader</p>

    <h2>EU-Streitschlichtung</h2>
    <p>
      Die Europ&auml;ische Kommission stellt eine Plattform zur
      Online-Streitbeilegung (OS) bereit:
      <a
        href="https://ec.europa.eu/consumers/odr/"
        target="_blank"
        rel="noopener noreferrer"
        >hier</a
      >.<br />
      Unsere E-Mail-Adresse finden Sie oben im Impressum.
    </p>

    <h2>
      Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
    </h2>
    <p>
      Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
      einer Verbraucherschlichtungsstelle teilzunehmen.
    </p>
  </main>
</template>
